














import { Component, Vue } from 'vue-property-decorator'
import CarriersFilter from '@/components/quote/filter-components/carriers.vue'
import PolicyCoveragesFilter from '@/components/quote/filter-components/policy-coverages.vue'
import { quoteModule } from '@/store/store-accessor'
import ReviewLauncherButton from '@/components/review/review-launcher-button.vue'
import QuoteStatusToggle from '@/components/quote/quotes-status-toggle.vue'

@Component({
  name: 'QuoteFilter',
  components: {
    PolicyCoveragesFilter,
    CarriersFilter,
    ReviewLauncherButton,
    QuoteStatusToggle
  }
})
export default class QuoteFilter extends Vue {
  get coverageOptions (): boolean {
    return quoteModule.quoteFilter.policyCoverages.options.length > 0
  }
}
