














import { Component, Vue, Prop } from 'vue-property-decorator'
import { FilterIcon } from 'vue-feather-icons'
@Component({
  name: 'FilterButton',
  components: {
    FilterIcon
  }
})
export default class FilterButton extends Vue {
  @Prop({ default: 0 }) numberOfCoveragesSelected!: number

  get message (): string | number {
    return this.numberOfCoveragesSelected > 0 ? this.numberOfCoveragesSelected : ''
  }
}
