


















import { Component, Vue, Prop } from 'vue-property-decorator'
import { ChevronDownIcon, ChevronUpIcon } from 'vue-feather-icons'

@Component({
  name: 'ShowAll',
  components: { ChevronDownIcon, ChevronUpIcon }
})
export default class ShowAll extends Vue {
  @Prop({ required: true, default: false }) isOpen !: boolean
  @Prop() optionText!: string[]
}
