































































































































































































































































































































import {
  XIcon,
  ChevronLeftIcon,
  RefreshCcwIcon,
  ChevronRightIcon
} from 'vue-feather-icons'
import ReviewLauncherButton from '@/components/review/review-launcher-button.vue'
import QuoteStatusToggle from '@/components/quote/quotes-status-toggle.vue'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import QuoteProductList from '@/components/quote/product-list.vue'
import BrokerageMixin from '@/mixins/brokerage-mixin'
import AgentAlert from '@/components/quote/agent-alert.vue'
import FilterButton from '@/components/quote/filter-components/filter-button.vue'
import Select from '@/components/controls/select/index.vue'
import QuoteComponent from '@/components/quote/quote.vue'
import {
  binderModule,
  brokerageModule,
  cartModule,
  quoteModule,
  underwritersModule
} from '@/store/store-accessor'
import QuoteFilter from '@/components/quote/filter-components/index.vue'

import {
  Quote,
  QuoteCoverages,
  QuoteStatus,
  sortOptions
} from '@/models/quote'
import ApplyFilterButton from '@/components/quote/filter-components/apply-filter.vue'
import DrawerFilter from '@/components/quote/drawer/filter.vue'
import DrawerPolicyCart from '@/components/quote/drawer/policy-cart.vue'
import DrawerSaveForLater from '@/components/save-for-later/drawer/index.vue'
import DrawerShareQuote from '@/components/share-quote/drawer/index.vue'
import DrawerHint from '@/components/quote/drawer/hint-container.vue'
import QuoteSelect from '@/components/quote/quote-select.vue'
import QuoteList from '@/components/quote/quote-list.vue'
import GhostQuote from '@/components/quote/ghost-states/ghost-quote.vue'
import GhostFilter from '@/components/quote/ghost-states/ghost-filter.vue'
import { ROUTES } from '@/router/routes'
import EventBus from '@/event-bus'
import FactList from '@/components/quote/fact-list/fact-list.vue'
import ProgressBar from '@/components/progress-bar/progress-bar.vue'
import { IProgressBar } from '@/models/progress-bar'
import CartButton from '@/components/quote/cart-button.vue'
import NoQuote from '@/views/NoQuote.vue'
import QuotesErrorsOverlay from '@/components/quote/quotes-errors-overlay.vue'
import ErrorsReviewRequiredOverlay from '@/components/quote/errors-review-required-overlay.vue'
import {
  TokenNotFoundError,
  GeneralErrors,
  CoverageErrors
} from '@/models/errors'
import ReviewOverlay from '@/components/review/review-overlay.vue'
import BusinessInfoToggle from '@/components/the-side-navigation/business-info-toggle.vue'
import DrawerBusinessInfo from '@/components/business-info/drawer/index.vue'
import DrawerQuotesStatus from '@/components/quote/quotes-status/drawer.vue'
import DrawerEditQuestion from '@/components/review/drawer/edit-question-drawer.vue'
import DrawerQuoteStatus from '@/components/quote/drawer/drawer-quote-status.vue'
import { validateQuestionByID } from '@/helpers/validations'
import { Events, NavigationDestinations } from '@/models/events'

@Component({
  name: 'UpdatedQuotes',
  components: {
    DrawerEditQuestion,
    DrawerQuoteStatus,
    NoQuote,
    CartButton,
    XIcon,
    FactList,
    DrawerHint,
    GhostQuote,
    GhostFilter,
    QuoteList,
    QuoteSelect,
    DrawerSaveForLater,
    DrawerShareQuote,
    DrawerPolicyCart,
    DrawerFilter,
    ApplyFilterButton,
    QuoteFilter,
    QuoteComponent,
    Select,
    FilterButton,
    AgentAlert,
    QuoteProductList,
    ChevronLeftIcon,
    ProgressBar,
    RefreshCcwIcon,
    ReviewOverlay,
    QuotesErrorsOverlay,
    ReviewLauncherButton,
    QuoteStatusToggle,
    BusinessInfoToggle,
    DrawerBusinessInfo,
    DrawerQuotesStatus,
    ErrorsReviewRequiredOverlay,
    ChevronRightIcon
  }
})
export default class UpdatedQuotes extends Mixins(BrokerageMixin) {
  @Prop() id!: string;
  noQualifiedQuotes = false;
  sort: sortOptions = sortOptions.ASC;
  sortOptions = [
    { key: sortOptions.ASC, label: 'Price - low to high' },
    { key: sortOptions.DESC, label: 'Price - high to low' }
  ];

  showFilter = false;
  // Variable to track time of initial run of creating quotes versus updating quotes.
  // Only used to control when to redirect to `NO_QUOTE` route or display empty state on quote screen
  initializingQuotes = true;
  hintCopy: string[] = [];
  loading = false;
  showQuotesErrorsOverlay = false;

  showingQuoteStatus: Quote | null = null;
  backDestination: NavigationDestinations = NavigationDestinations.QUOTES_VIEW;
  showQuoteStatusDrawer (val: Quote) {
    this.showingQuoteStatus = val
  }

  isPolicyStartDateInvalid = false;
  QuoteStatus = QuoteStatus;

  @Watch('quotes')
  onQuotesChanged (quotesData: Quote[]) {
    const progressBar = this.$refs['progress-bar'] as unknown as IProgressBar
    if (
      quotesData.length &&
      quotesData.some((quote) => quote.status === QuoteStatus.QUEUED)
    ) {
      progressBar.start()
      this.noQualifiedQuotes = false
    }

    if (
      quotesData.length &&
      quotesData.every((quote) => quote.status !== QuoteStatus.QUEUED)
    ) {
      progressBar.finish()
      this.initializingQuotes = false
      this.noQualifiedQuotes = false
    }

    if (
      quotesData.length &&
      quotesData.every((quote) => quote.status !== QuoteStatus.QUEUED) &&
      !quotesData.some((quote) =>
        [QuoteStatus.QUOTED, QuoteStatus.REFERRED].includes(quote.status)
      )
    ) {
      progressBar.finish()
      this.noQualifiedQuotes = true
    }
    if (quotesData.length === 0 && this.initializingQuotes) {
      this.initializingQuotes = false
      progressBar.finish()
    }
  }

  async answersChanged () {
    await binderModule.setDrawerState(false)
    await binderModule.setReviewOverlay()
    await this.resetQuotes()
    this.initializingQuotes = true
    const progressBar = this.$refs['progress-bar'] as unknown as IProgressBar
    progressBar.start()
    this.clearFilter()
    await cartModule.clearCart()
  }

  toggleQuotesStatusOverlay () {
    binderModule.setDrawerState(true)
    binderModule.setQuotesStatusDrawer()
  }

  get totalNumberOfSelectedCoverages (): number {
    const carriers = quoteModule.carriersSelected?.length || 0
    const coverages = quoteModule.policyCoverageSelected?.length || 0
    return carriers + coverages
  }

  get showCartButton () {
    return (
      this.cartItems.length > 0 && this.$router.currentRoute.name === 'quotes'
    )
  }

  get quotes (): Quote[] {
    return quoteModule.sortedFilteredQuotes
  }

  get referredQuotes (): Quote[] {
    return quoteModule.sortedFilteredQuotes.filter(
      (quote) => quote.status === QuoteStatus.REFERRED
    )
  }

  get bindableQuotes (): Quote[] {
    return quoteModule.sortedFilteredQuotes.filter((quote) =>
      [QuoteStatus.QUOTED, QuoteStatus.QUEUED].includes(quote.status)
    )
  }

  get coverageData (): QuoteCoverages[] {
    return quoteModule.quoteCoverages
  }

  get showSaveForLater (): boolean {
    return binderModule.toggleSaveForLater
  }

  get showShareQuote (): boolean {
    return binderModule.toggleShareQuote
  }

  get showHint (): boolean {
    return binderModule.toggleHint
  }

  get quotesLoading () {
    return (
      this.initializingQuotes ||
      (this.quotes.length > 0 &&
        this.quotes.some((quote) => quote.status === QuoteStatus.QUEUED))
    )
  }

  get showCart (): boolean {
    return binderModule.toggleCart
  }

  get cartItems () {
    return cartModule.cartItems
  }

  get quotePendingUpdate (): string | null {
    return cartModule.pendingUpdateQuote
  }

  toggleQuoteErrorsOverlay () {
    if (!document.querySelector('.overlay.error-review-required')) {
      this.showQuotesErrorsOverlay = true
    }
  }

  async created () {
    window.scrollTo(0, 0)
    this.sort = quoteModule.sort
    try {
      await binderModule.checkApplication()
    } catch (e: any) {
      if (
        e instanceof TokenNotFoundError ||
        e.code === GeneralErrors.GENERAL_UNAUTHORIZED
      ) {
        this.$router.push({ name: ROUTES.LINK_NOT_FOUND })
      } else {
        throw e
      }
    }

    try {
      await quoteModule.getProductTypes()
      await quoteModule.getProducts()
      await underwritersModule.getUnderwriters()
      await quoteModule.initializeQuotes({
        id: this.id
      })
    } catch (e: any) {
      // opens the review popup if there is missing required answers,
      // except for the PolicyStartDate that has it own overlay
      if (e.code === CoverageErrors.MISSING_REQUIRED_ANSWER) {
        if (!validateQuestionByID('PolicyStartDate').valid) {
          this.isPolicyStartDateInvalid = true
        } else {
          binderModule.setDrawerState(true)
          binderModule.setReviewOverlay()
        }
      } else {
        this.toggleQuoteErrorsOverlay()
      }
    }

    document.title = brokerageModule.brokerage?.name
      ? `${brokerageModule.brokerage?.name} | ${this.$route?.meta?.title}`
      : this.$route?.meta?.title
  }

  mounted () {
    EventBus.$on(Events.TOGGLE_HINT, (hintCopy: string[]) => {
      binderModule.setShowHint()
      this.hintCopy = hintCopy
    })
    EventBus.$on(Events.SELECT_PRODUCT, (productSlug: string) => {
      this.filterQuotesByProduct(productSlug)
    })

    EventBus.$on(
      Events.SHOW_QUOTE_STATUS,
      (
        val: Quote,
        backDestination: NavigationDestinations = NavigationDestinations.QUOTES_VIEW
      ) => {
        this.showingQuoteStatus = val
        this.backDestination = backDestination
      }
    )
  }

  updateSort (selection: sortOptions) {
    this.loading = true
    this.sort = selection
    quoteModule.updateSort(this.sort)
    setTimeout(() => {
      this.loading = false
    }, 500)
  }

  toggleFilter () {
    this.showFilter = !this.showFilter
  }

  clearFilter () {
    this.toggleLoading()
    quoteModule.updatePolicyCoverageSelected([])
    quoteModule.updateCarrierSelected([])
  }

  updateFilter () {
    quoteModule.applyFilter({
      carrier: quoteModule.carriersSelected,
      policyCoverages: quoteModule.policyCoverageSelected
    })
  }

  reloadPage () {
    binderModule.setEditQuestion('')
    this.$router.go(0)
  }

  toggleCart () {
    binderModule.setShowCart()
  }

  toggleSaveForLater () {
    binderModule.setSaveForLater()
  }

  toggleShareQuote () {
    binderModule.setShareQuote()
  }

  async updateQuote (quote: Quote) {
    await quoteModule.updateQuote({
      id: this.id,
      quote: quote
    })
  }

  toggleLoading () {
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 500)
  }

  async resetQuotes () {
    this.noQualifiedQuotes = false
    this.loading = true
    cartModule.clearCart()
    await quoteModule.resetQuotes({
      id: this.id
    })
    this.loading = false
  }

  /**
   * Capturing emitted value from ProductList component and setting
   * product slug in quote module to filter quotes
   *
   * @param   {string}  productSlug  [Product slug to filter quotes]
   *
   */
  async filterQuotesByProduct (productSlug: string) {
    this.toggleLoading()
    await quoteModule.updateFiltersByProductSlug(productSlug)
  }

  /**
   * Setting the quote id of the quote that is edited in cart
   */
  async setQuotePendingUpdate (quoteId: string | null) {
    await cartModule.setPendingUpdateQuote(quoteId)
  }

  get isEditingQuestion (): string {
    return binderModule.toggleEditQuestion
  }
}
