
































































































































import CalloutBox from '@/components/callout-box/index.vue'
import { InfoIcon, CheckIcon } from 'vue-feather-icons'
import { defineComponent, ref, computed } from '@vue/composition-api'
import TextInput from '@/components/controls/text-input/index.vue'
import { Link } from '@/models/link'
import { binderModule } from '@/store/store-accessor'
import ShareQuoteConfirmation from '@/components/share-quote/confirmation.vue'
import LoadingIcon from '@/components/loading-icon/index.vue'
import copy from 'copy-to-clipboard'
import useBrokerage from '@/use/useBrokerage'
import { validateEmail } from '@/helpers/validations'

export default defineComponent({
  name: 'ShareQuoteForm',
  components: {
    TextInput,
    ShareQuoteConfirmation,
    LoadingIcon,
    CheckIcon,
    InfoIcon,
    CalloutBox
  },
  props: {
    progress: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const ShareQuotesErrorsStates = {
      SAVE_FOR_LATER: 'saveForLater',
      COPY_LINK: 'copyLink'
    }
    const { brokerageEmail, brokeragePhone, brokerageName } = useBrokerage()
    const copyButtonText = ref('Copy')
    const email = ref('')
    const error = ref('')
    const isLoading = ref(false)
    const showSuccessfullCopy = ref(false)
    const showSuccessfullMessage = ref(false)
    const showErrorMessage = ref('')

    const isValidEmail = computed(() => {
      return validateEmail(email.value)
    })

    const resetForm = () => {
      showSuccessfullMessage.value = false
      isLoading.value = false
    }

    const saveApplication = async () => {
      showErrorMessage.value = ''
      isLoading.value = true
      const data: Link = {
        email: email.value,
        meta: {
          progress: props.progress
        }
      }
      try {
        await binderModule.saveForLater(data)
      } catch (error: any) {
        isLoading.value = false
        showErrorMessage.value = ShareQuotesErrorsStates.SAVE_FOR_LATER
        return
      }
      showSuccessfullMessage.value = true
      // revert back to form, in case user would like to change email address
      setTimeout(() => {
        resetForm()
      }, 5000)
    }

    const saveApplicationToQuotesPage = async () => {
      const data: Link = {
        meta: {
          progress: 'quotes'
        }
      }
      try {
        await binderModule.saveForLater(data)
      } catch (error: any) {
        isLoading.value = false
        showErrorMessage.value = ShareQuotesErrorsStates.COPY_LINK
      }
    }

    const linkToCopy = computed(() => {
      return `${window.location.origin}/resume#${binderModule.tokenData}`
    })

    const copyLink = async () => {
      showErrorMessage.value = ''
      if (linkToCopy) {
        await saveApplicationToQuotesPage()
        copy(linkToCopy.value)
        copyButtonText.value = 'Copied'
        showSuccessfullCopy.value = true
        window.setTimeout(() => {
          copyButtonText.value = 'Copy'
          showSuccessfullCopy.value = false
        }, 5000)
      }
    }

    return {
      brokeragePhone,
      brokerageName,
      brokerageEmail,
      isLoading,
      isValidEmail,
      email,
      error,
      showSuccessfullCopy,
      copyButtonText,
      copyLink,
      linkToCopy,
      showSuccessfullMessage,
      saveApplication,
      showErrorMessage,
      ShareQuotesErrorsStates
    }
  }
})
