

















































































import { XIcon, CheckIcon, HelpCircleIcon } from 'vue-feather-icons'
import { defineComponent, computed } from '@vue/composition-api'
import Drawer from '@/components/drawer/index.vue'
import { binderModule, underwritersModule, quoteModule } from '@/store/store-accessor'
import UnderwriterLogo from '@/components/quote/underwriter-logo.vue'
import { UnderwriterSlug } from '@/models/underwriter'
import EventBus from '@/event-bus'
import { Quote, QuoteStatus } from '@/models/quote'
import { Events, NavigationDestinations } from '@/models/events'

export default defineComponent({
  name: 'DrawerQuotesStatus',
  components: { Drawer, CheckIcon, XIcon, UnderwriterLogo, HelpCircleIcon },
  setup () {
    const quoteStatusSections = [
      { status: QuoteStatus.QUOTED, title: 'Quotes available from:' },
      { status: QuoteStatus.REFERRED, title: 'Referred submissions:' },
      { status: QuoteStatus.REJECTED, title: 'Declined quotes from:' }
    ]

    const toggleQuotesStatusDrawer = () => {
      binderModule.setQuotesStatusDrawer()
    }

    const getUnderwriter = (underwriterSlug: UnderwriterSlug) => {
      return underwritersModule.underwriterBySlug(underwriterSlug)
    }

    const showQuotesStatusDrawer = computed(() => {
      return binderModule.toggleQuotesStatusDrawer
    })

    const getQuotesByStatus = (status: string) => {
      return quoteModule.quotesByStatus(status)
    }

    const quotedStatus = computed(() => {
      return QuoteStatus.QUOTED
    })

    const getIconByStatus = (status: string) => {
      const availableIcons: any = {
        [QuoteStatus.QUOTED]: {
          colorClass: 'bg-success',
          icon: 'CheckIcon'
        },
        [QuoteStatus.REJECTED]: {
          colorClass: 'bg-error',
          icon: 'XIcon'
        }
      }
      return availableIcons[status]
    }

    const showStatusDetails = (quote: Quote) => {
      EventBus.$emit(Events.SHOW_QUOTE_STATUS, quote, NavigationDestinations.QUOTES_STATUS)

      toggleQuotesStatusDrawer()
    }

    const businessName = computed(() => {
      return binderModule.answers.BusinessName
    })

    return {
      toggleQuotesStatusDrawer,
      showQuotesStatusDrawer,
      getUnderwriter,
      quoteStatusSections,
      quotedStatus,
      getQuotesByStatus,
      getIconByStatus,
      businessName,
      showStatusDetails
    }
  }
})
