







































import { Component, Vue } from 'vue-property-decorator'
import ListHeader from '@/components/quote/filter-components/list-header.vue'
import { quoteModule } from '@/store/store-accessor'
import { QuoteFilterItems, QuoteFilterItemsOptions } from '@/models/filter'
import ShowAll from '@/components/quote/filter-components/show-all.vue'
import ListItem from '@/components/quote/filter-components/list-item.vue'

@Component({
  name: 'CarriersFilter',
  components: { ListHeader, ShowAll, ListItem }
})
export default class CarriersFilter extends Vue {
  showAllCarriers = false

  /**
   * getter for selected carriers filter-components
   */
  get selectedCarriers () {
    return quoteModule.carriersSelected
  }

  /**
   * setter for selected carriers filter-components
   */
  set selectedCarriers (value: string[]) {
    this.$emit('loading')
    quoteModule.updateCarrierSelected(value)
  }

  /**
   * Carrier filter-components accessor from quote module
   */
  get carrierFilter (): QuoteFilterItems {
    return quoteModule.quoteFilter.carrier
  }

  get carrierFilterOptions (): QuoteFilterItemsOptions[] {
    return (this.showAllCarriers
      ? quoteModule.quoteFilter.carrier.options
      : quoteModule.quoteFilter.carrier.options?.slice(0, 3)) ?? []
  }

  get displayToggle (): boolean {
    return quoteModule.quoteFilter.carrier.options.length > 3
  }

  /**
   * Select all carriers function
   * - Loop through all carrier option and create a string[] of all keys
   */
  carrierSelectAll () {
    const allCarriers = this.carrierFilter.options?.map(option => option.key) ?? []
    quoteModule.updateCarrierSelected(allCarriers as string[])
  }

  /**
   * Clear carrier selection
   */
  clearSelection () {
    quoteModule.updateCarrierSelected([])
  }

  /**
   * Show all carriers toggle
   */
  toggleShowAllCarriers () {
    this.showAllCarriers = !this.showAllCarriers
  }
}
