



























import { Component, Vue } from 'vue-property-decorator'
import Drawer from '@/components/drawer/index.vue'
import QuoteFilter from '@/components/quote/filter-components/index.vue'
import ApplyFilterButton from '@/components/quote/filter-components/apply-filter.vue'
import { ChevronLeftIcon } from 'vue-feather-icons'

@Component({
  name: 'DrawerFilter',
  components: { ApplyFilterButton, QuoteFilter, Drawer, ChevronLeftIcon }
})
export default class DrawerFilter extends Vue {

}
