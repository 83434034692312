





































import { defineComponent } from '@vue/composition-api'
import { CheckIcon } from 'vue-feather-icons'
import IconBase from '@/components/icon-library/icon-base.vue'
import SentQuote from '@/components/icon-library/sent-quote.vue'

export default defineComponent({
  name: 'SaveForLaterConfirmation',
  components: {
    SentQuote,
    IconBase,
    CheckIcon
  },
  props: {
    email: {
      type: String,
      required: true
    }

  },
  emits: ['close-drawer']
})
