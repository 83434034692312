















































































import { defineComponent, reactive } from '@vue/composition-api'
import BusinessInfoToggle from '@/components/the-side-navigation/business-info-toggle.vue'
import { ExternalLinkIcon, ChevronRightIcon, RefreshCcwIcon } from 'vue-feather-icons'
import LoadingIcon from '@/components/loading-icon/index.vue'
import { ROUTES } from '@/router/routes'
import VueRouter from 'vue-router'
import useLoadable from '@/use/useLoadable'
import QuoteStatusToggle from '@/components/quote/quotes-status-toggle.vue'
import { binderModule } from '@/store/store-accessor'
import { Events } from '@/models/events'

export default defineComponent({
  name: 'NoQuote',
  components: {
    BusinessInfoToggle,
    ExternalLinkIcon,
    ChevronRightIcon,
    RefreshCcwIcon,
    QuoteStatusToggle,
    LoadingIcon
  },
  emits: ['reset-quotes'],
  setup (_props, { emit, root }) {
    const router = reactive<VueRouter>(root.$router)
    const { showBtnLoading, btnLoading } = useLoadable()

    const restartApplication = async () => {
      showBtnLoading()
      const preApplicationId = await binderModule.restartApplication()

      return router.push({
        name: ROUTES.PRE_APPLICATION,
        params: { id: preApplicationId }
      })
    }

    const resetQuotes = async () => {
      emit(Events.RESET_QUOTES)
    }

    const toggleReviewOverlay = () => {
      binderModule.setDrawerState(true)
      binderModule.setReviewOverlay()
    }

    return {
      btnLoading,
      restartApplication,
      toggleReviewOverlay,
      resetQuotes
    }
  }
})
