










import { binderModule } from '@/store/store-accessor'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'QuoteStatusToggle',
  setup () {
    const toggleQuotesStatusOverlay = () => {
      binderModule.setDrawerState(true)
      binderModule.setQuotesStatusDrawer()
    }
    return { toggleQuotesStatusOverlay }
  }
})

