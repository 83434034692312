











































import { Component, Vue } from 'vue-property-decorator'
import ListHeader from '@/components/quote/filter-components/list-header.vue'
import { quoteModule } from '@/store/store-accessor'
import { QuoteFilterItems, QuoteFilterItemsOptions } from '@/models/filter'
import ShowAll from '@/components/quote/filter-components/show-all.vue'
import ListItem from '@/components/quote/filter-components/list-item.vue'
import { Quote } from '@/models/quote'

@Component({
  name: 'PolicyCoveragesFilter',
  components: { ListHeader, ShowAll, ListItem }
})
export default class PolicyCoveragesFilter extends Vue {
  showAllPolicyCoverages = false;

  /**
   * getter for selected policy coverage filter-components
   */
  get selectedPolicyCoverage () {
    return quoteModule.policyCoverageSelected
  }

  /**
   * setter for selected policy coverage filter-components
   */
  set selectedPolicyCoverage (value: string[]) {
    this.$emit('loading')
    quoteModule.updatePolicyCoverageSelected(value)
  }

  /**
   * Policy coverage filter-components accessor from quote module
   */
  get policyCoverageFilter (): QuoteFilterItems {
    return quoteModule.quoteFilter.policyCoverages
  }

  get policyCoverageOptions (): QuoteFilterItemsOptions[] {
    const options = (this.showAllPolicyCoverages
      ? quoteModule.quoteFilter.policyCoverages.options
      : quoteModule.quoteFilter.policyCoverages.options?.slice(0, 3)) ?? []
    return options.filter(option => !option.label.includes('http'))
  }

  get displayToggle (): boolean {
    return quoteModule.quoteFilter.policyCoverages.options.length > 3
  }

  get quotes (): Quote[] | undefined {
    return quoteModule.sortedFilteredQuotes
  }

  /**
   * Select all policy coverages function
   * - Loop through all policy coverages option and create a string[] of all keys
   */
  policyCoverageSelectAll () {
    const allCoverages = this.policyCoverageFilter.options?.map(option => option.label) ?? []
    quoteModule.updatePolicyCoverageSelected(allCoverages as string[])
  }

  hasQuotesPresent (quotesId: string[]) {
    return quotesId ? quotesId.some(id => JSON.stringify(this.quotes).includes(id)) : false
  }

  /**
   * Clear policy coverage selection
   */
  clearSelection () {
    quoteModule.updatePolicyCoverageSelected([])
  }

  /**
   * Show all policy coverages toggle
   */
  toggleShowAllPolicyCoverages () {
    this.showAllPolicyCoverages = !this.showAllPolicyCoverages
  }
}
