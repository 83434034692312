






















import { Component, Prop, Vue } from 'vue-property-decorator'
import Drawer from '@/components/drawer/index.vue'
import Hint from '@/components/hint/index.vue'
import { ChevronLeftIcon } from 'vue-feather-icons'
import EventBus from '@/event-bus'
import { Events } from '@/models/events'

@Component({
  name: 'DrawerHint',
  components: { Hint, Drawer, ChevronLeftIcon }
})
export default class DrawerHint extends Vue {
  @Prop({ required: true, default: () => [] }) hintCopy!: string[]
  @Prop({ default: true }) isOpen!: boolean

  toggleHint () {
    EventBus.$emit(Events.TOGGLE_HINT, [])
  }
}
