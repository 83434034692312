






















import { defineComponent } from '@vue/composition-api'
import { XIcon } from 'vue-feather-icons'
import Drawer from '@/components/drawer/index.vue'
import ShareQuoteForm from '@/components/share-quote/index.vue'

export default defineComponent({
  name: 'DrawerShareQuote',
  components: { Drawer, ShareQuoteForm, XIcon },
  props: {
    progress: {
      type: String,
      required: true,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: true
    }
  },
  emits: ['close-drawer']
})
