























































import { defineComponent, watch } from '@vue/composition-api'
import ReviewLauncherButton from '@/components/review/review-launcher-button.vue'
import { binderModule } from '@/store/store-accessor'
import BusinessInfoToggle from '@/components/the-side-navigation/business-info-toggle.vue'

export default defineComponent({
  name: 'ErrorsReviewRequiredOverlay',
  components: {
    ReviewLauncherButton,
    BusinessInfoToggle
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    watch(() => props.isOpen, (val) => {
      binderModule.setDrawerState(val)
    })

    const editQuestion = () => {
      binderModule.setEditQuestion('PolicyStartDate')
    }

    return { editQuestion }
  }
})
