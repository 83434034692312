






























































































import {
  defineComponent,
  computed
} from '@vue/composition-api'
import Drawer from '@/components/drawer/index.vue'
import { ChevronLeftIcon, XIcon, HelpCircleIcon } from 'vue-feather-icons'
import UnderwriterLogo from '@/components/quote/underwriter-logo.vue'
import { Quote, QuoteStatus } from '@/models/quote'
import { underwritersModule, quoteModule } from '@/store/store-accessor'
import { Events, NavigationDestinations } from '@/models/events'

export default defineComponent({
  name: 'DrawerQuoteStatus',
  components: {
    Drawer, ChevronLeftIcon, XIcon, UnderwriterLogo, HelpCircleIcon
  },
  props: {
    quote: {
      type: Object as () => Quote | null,
      default: null
    },
    backDestination: {
      type: String,
      default: NavigationDestinations.QUOTES_VIEW
    }
  },
  emits: [Events.CLOSE_DRAWER, Events.SHOW_QUOTE_STATUS],
  setup (props, { emit }) {
    const isReferred = computed(() => {
      return props.quote?.status === QuoteStatus.REFERRED
    })

    const isBindable = computed(() => {
      return props.quote?.status === QuoteStatus.QUOTED
    })

    const isDeclined = computed(() => {
      return props.quote?.status === QuoteStatus.REJECTED
    })

    const quoteProduct = computed(() => {
      return quoteModule.productList.find(
        product => product.id === props.quote?.productId
      )
    })

    const externalQuoteReference = computed(() => {
      return props.quote?.externalQuoteReference
    })

    const underwriter = computed(() => {
      const id = quoteProduct.value?.underwriterId
      if (!id) return undefined
      return underwritersModule.underwriterById(id)
    })

    const goBack = () => {
      if (props.backDestination === NavigationDestinations.QUOTES_VIEW) emit(Events.CLOSE_DRAWER)
      if (props.backDestination === NavigationDestinations.QUOTES_STATUS) {
        emit(Events.SHOW_QUOTE_STATUS)
        emit(Events.CLOSE_DRAWER)
      }
    }

    return {
      isReferred,
      isBindable,
      isDeclined,
      underwriter,
      quoteProduct,
      externalQuoteReference,
      goBack
    }
  }

})
