









import { defineComponent, onMounted, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'FactList',
  setup () {
    const randomFactList = [
      'The Padres have signed the same player for 23 years so he doesn’t lose his health insurance.',
      'Starbucks pays more for employee health insurance than it pays for coffee.',
      'No insurance company will underwrite Jackie Chan’s productions.',
      'America Ferrera had her smile insured for $10,000,000.',
      'An insurance policy exists for death by excessive laughter at a movie theater.',
      'The very first insurance contract was signed in 1347.',
      'An insurance company offered a cash reward to anyone who could capture the Loch Ness monster.',
      'Gene Simmons once insured his tongue for $1,000,000.',
      'The phenomenon of insuring body parts started in 1920.',
      'Alien abduction insurance has been available in the U.S. since 1987.',
      'The word ‘insurance’ originally meant an ‘engagement to marry’.',
      '‘Hole-in-one’ insurance allows golfers to offer huge prizes to anyone who gets a hole-in-one.',
      'Taco Bell once purchased taco insurance.',
      'Your home insurance probably covers volcano and meteor damage',
      'America\'s insurance history predates the American revolution'
    ]
    const fact = ref('')

    const setFact = () => {
      setInterval(() => {
        fact.value = randomFactList[Math.floor(Math.random() * 15) + 1]
      }, 5000)
    }

    onMounted(() => {
      fact.value = randomFactList[Math.floor(Math.random() * 15) + 1]
      setFact()
    })
    return { fact }
  }
})
