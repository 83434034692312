










import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'ApplyFilterButton'
})
export default class ApplyFilterButton extends Vue {

}
