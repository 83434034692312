




























































import { defineComponent, computed } from '@vue/composition-api'
import { Quote, QuoteCoverages, QuoteStatus } from '@/models/quote'
import { Events } from '@/models/events'
import QuoteCard from '@/components/quote/quote.vue'
import GhostQuote from '@/components/quote/ghost-states/ghost-quote.vue'
import EventBus from '@/event-bus'

export default defineComponent({
  name: 'QuoteList',
  components: { GhostQuote, QuoteCard },
  props: {
    quotes: {
      type: Array as () => Quote[],
      required: true
    },
    coverages: {
      type: Array as () => QuoteCoverages[],
      required: true
    },
    initialRun: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    quotesLoading: {
      type: Boolean,
      default: false
    },
    statusToShow: {
      type: Array as () => QuoteStatus[],
      default: () => [QuoteStatus.QUOTED, QuoteStatus.QUEUED]
    },
    showQueued: {
      type: Boolean,
      default: true
    },
    customLayout: {
      type: Boolean,
      default: false
    },
    customShowRates: {
      type: Boolean,
      default: false
    },
    customShowQuoteSelect: {
      type: Boolean,
      default: false
    },
    customShowCoverages: {
      type: Boolean,
      default: false
    },
    customShowCTA: {
      type: Boolean,
      default: false
    },
    customShowLearnMore: {
      type: Boolean,
      default: false
    },
    customShowLogo: {
      type: Boolean,
      default: false
    }
  },
  emits: [Events.UPDATE_QUOTE, Events.SHOW_QUOTE_STATUS],
  setup (props, { emit }) {
    const isQueued = (quote: Quote) => {
      return quote.status === QuoteStatus.QUEUED
    }

    const isLoaded = (quote: Quote) => {
      return props.statusToShow.includes(quote.status)
    }

    const hasPaymentOptions = (quote: Quote): boolean => {
      return !!(quote.paymentOptions && quote.paymentOptions.length)
    }

    const updateQuote = (quote: Quote) => {
      emit(Events.UPDATE_QUOTE, quote)
    }

    const quoteList = computed(() => {
      return props.quotes.filter((quote) =>
        props.statusToShow.includes(quote.status)
      )
    })

    const getCoverageData = (quoteId: string) => {
      return props.coverages.find((data) => data.quoteId === quoteId)
    }
    const toggleLearnMoreDrawer = (quote: Quote) => {
      EventBus.$emit('show-quote-status', quote)
      emit(Events.SHOW_QUOTE_STATUS, quote)
    }

    return {
      quoteList,
      isQueued,
      toggleLearnMoreDrawer,
      isLoaded,
      updateQuote,
      getCoverageData,
      hasPaymentOptions
    }
  }
})
