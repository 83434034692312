






















import { Component, Vue, Prop } from 'vue-property-decorator'
import { AlertCircleIcon } from 'vue-feather-icons'
import EventBus from '@/event-bus'
import { Events } from '@/models/events'

@Component({
  name: 'ListItem',
  components: {
    AlertCircleIcon
  }
})
export default class FilterButton extends Vue {
  @Prop() option!: Record<string, unknown>
  @Prop() hint!: string[]
  toggleHint (hint: string[]) {
    EventBus.$emit(Events.TOGGLE_HINT, hint)
  }
}
